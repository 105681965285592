import { mapActions, mapState, mapGetters } from 'vuex'
import { unmaskMoney, currencyFormat } from '@/helpers'
import { getDonations, getTotalsDonations } from '@/queries/donations/donations.queries'
import { createReportDonations } from '@/queries/donations/donations.mutations'

export default {
  name: 'TDonorsPage',
  title: ({ title }) => title,
  components: {
    TFilter: () => import('@/components/filter'),
    TStatusBar: () => import('@/components/status/bar'),
    TStatusBarItem: () => import('@/components/status/item.vue'),
    TDonationsTable: () => import('@/components/donations/table.vue'),
    TDonationsFilters: () => import('@/components/donations/filters.vue')
  },
  data () {
    return {
      title: this.$tc('Donation', 2),
      total: 3600,
      statusItems: [
        { key: 'active', label: 'Ativas', value: 0, itemClass: 'active' },
        { key: 'inactive', label: 'Inativas', value: 0, itemClass: 'inactive' },
        { key: 'finished', label: 'Finalizadas', value: 0, itemClass: 'finished' }
      ],
      totals: { key: 'total', label: 'Total de doações', value: 0 },
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0",
      limit: 10,
      isLoadingTotals: true,
      modals: {
        modalConfirmReport: ''
      }
    }
  },
  computed: {
    ...mapState({
      donationsData: ({ donations }) => donations.donationsData,
      metaDataDonationsList: ({ donations }) => donations.metaDataDonationsList,
      filterForm: ({ donations }) => donations.filterForm,
      filterItems: ({ donations }) => donations.filterItems,
      isLoading: ({ donations }) => donations.isLoading,
      orderParams: ({ donations }) => donations.orderParams
    }),
    ...mapGetters({
      user: 'getUser'
    })
  },
  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('donations', [ 'setDonationsData', 'setMetaDataDonationsList',
      'toggleLoadingList', 'addItem', 'removeItem', 'clearFilterForm', 'setOrderParams' ]),
    ...mapActions('filter', [ 'toggleLoading', 'clearFilterItems' ]),
    ...mapActions('notifications', [ 'toggleNotification', 'setNotificationMessage' ]),

    applyFilter () {
      const { filterBy, equalTo, fieldOne, fieldTwo } = this.filterForm

      if (filterBy && equalTo && fieldOne) {
        if (!this.filterItemAlreadySelected(filterBy)) {

          let allowAddItem = false

          if (filterBy.key === 'donationValue') {
            const donationValue = unmaskMoney(fieldOne)
            if (donationValue > 0) {
              allowAddItem = true
            }
          } else {
            allowAddItem = true
          }

          if (allowAddItem) {
            this.toggleLoading(true)
            setTimeout(() => {
              this.addItem({ filterBy, equalTo, fieldOne, fieldTwo })
              this.searchDonations()
              this.getTotalsDonations()
              this.clearFilterForm()
              $('.tui.ui.dropdown').dropdown('clear')
              this.toggleLoading(false)
            }, 1000)
          }
        }
      }

    },

    filterItemAlreadySelected (filterBy) {
      return this.filterItems.length !== 0 && this.filterItems.find(filter => filter.filterBy.key === filterBy.key)
    },

    filtersUpdated (item) {
      this.removeItem(item)
      this.searchDonations()
      this.getTotalsDonations()
    },

    searchDonations ( params = {} ) {
      let searchParams = this.mountSearchParams()
      searchParams = {
        ...searchParams,
        ...params
      }
      this.toggleLoadingList(true)
      getDonations( searchParams ).then( res => {
        const { collection, metadata } = res.data.subscriptions
        this.setDonationsData(collection)
        this.setMetaDataDonationsList(metadata)
        this.updateMetadataTable()
        this.toggleLoadingList()
      }).catch(e => {
        Vue.$log.error('error', e)
        this.toggleLoadingList()
      })
    },

    getTotalsDonations ( params = {} ) {
      this.isLoadingTotals = true
      let searchParams = this.mountSearchParams()
      searchParams = {
        ...searchParams,
        ...params
      }
      getTotalsDonations( searchParams ).then( res => {
        const { totalSubscriptions } = res.data
        this.statusItems.find( item => {
          if (item.key === 'active') {
            item.value = currencyFormat(totalSubscriptions.active)
          }
          if (item.key === 'inactive') {
            item.value = currencyFormat(totalSubscriptions.inactive)
          }
          if (item.key === 'finished') {
            item.value = currencyFormat(totalSubscriptions.finalized)
          }
        })
        this.isLoadingTotals = false
      }).catch(e => {
        Vue.$log.error('error', e)
        this.isLoadingTotals = false
      })
    },

    prevPage () {
      if (this.currentPage === 1) return
      const page = Number(this.currentPage) - 1
      let params = { page }
      this.searchDonations( params )
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return
      const page = Number(this.currentPage) + 1
      let params = { page }
      this.searchDonations( params )
    },

    goToPage (pageNumber) {
      const page = Number(pageNumber)
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      let params = { page }
      this.searchDonations( params )
    },

    updateMetadataTable () {
      if ( this.metaDataDonationsList ) {
        this.totalPages = `${this.metaDataDonationsList.totalPages}`
        this.currentPage = `${this.metaDataDonationsList.currentPage}`
        this.pagesSelected = `${this.metaDataDonationsList.limitValue}`
        this.total = this.metaDataDonationsList.totalCount
        this.totals.value = this.metaDataDonationsList.totalCount
      }
    },

    mountSearchParams () {
      let params = {}
      if (this.filterItems.length > 0) {
        const status = this.filterItems.find( item => item.filterBy.key === 'status' )
        const paymentMethod = this.filterItems.find( item => item.filterBy.key === 'paymentMethod' )
        const name = this.filterItems.find( item => item.filterBy.key === 'name' )
        const donationValue = this.filterItems.find( item => item.filterBy.key === 'donationValue' )
        const periodFilter = this.filterItems.find( item => item.filterBy.key === 'period' )

        if (status) {
          params.status = status.fieldOne.key
        }

        if (name) {
          params.customerName = name.fieldOne
        }

        if (paymentMethod) {
          params.paymentMethod = paymentMethod.fieldOne.key
        }

        if (donationValue) {
          params.amountOperator = donationValue.equalTo.key
          params.amount = unmaskMoney(donationValue.fieldOne)
        }

        if (periodFilter) {
          params.createdAtBeginDate = periodFilter.fieldOne
          params.createdAtEndDate = periodFilter.fieldTwo
        }

      }

      if (this.limit) {
        params.limit = this.limit
      }

      if (this.orderParams) {
        params.orderBy = this.orderParams.orderBy
        params.order = this.orderParams.order
      }

      return params
    },

    bindPlugins () {
      $('.tui.ui.accordion').accordion()
      this.modals.modalConfirmReport = $(this.$refs.modalConfirmReport).modal()
    },

    orderBy (orderObject) {
      const { field, order } = orderObject
      this.setOrderParams({ orderBy: field, order })
      this.searchDonations()
    },

    callCreateReport() {
      this.modals.modalConfirmReport.modal('show')
    },

    generateReport() {
      let searchParams = this.mountSearchParams()

      searchParams = {
        ...searchParams,
        totalCount: this.totals.value
      }

      createReportDonations(searchParams)
        .then(res => {
          const { success, errors } = res.data.createReportSubscriptions
          if (!success && errors) {
            const { message } = errors[0]
            this.setNotificationMessage(message)
            this.toggleNotification({ active: true, type: 'error' })
          } else if (success) {
            this.setNotificationMessage("Sua solicitação foi recebida e o arquivo será enviado para o seu e-mail. Isso pode demorar alguns minutos.")
            this.toggleNotification({ active: true, type: 'processing' })
          }
        }).catch(e => {
          Vue.$log.error('error', e)
        })
    }
  },

  mounted () {
    this.bindPlugins()
    this.changeBreadcrumbItems([
      { to: { name: 'donations' }, title: 'Doações', active: true }
    ])

    this.searchDonations()
    this.getTotalsDonations()
  },

  beforeDestroy () {
    this.modals.modalConfirmReport.remove()
  }
}
