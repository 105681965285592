import gql from 'graphql-tag'

const query = gql`
  query totalSubscriptions(
    $customerId: ID,
    $paymentMethod: String,
    $status: String,
    $customerName: String,
    $amount: Int,
    $amountOperator: String
  ) {
    totalSubscriptions(
      customerId: $customerId,
      paymentMethod: $paymentMethod,
      status: $status,
      customerName: $customerName,
      amount: $amount,
      amountOperator: $amountOperator
  ) {
    active
    finalized
    inactive
  }
}`

async function getTotalsDonations ( params = {} ) {
    const { $api } = Vue.prototype
    const result = await $api.query({
        query,
        variables: params
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getTotalsDonations }
