import gql from 'graphql-tag'

const query = gql`
  query competence(
    $id: ID!
  ) {
    competence(
      id: $id
  ) {
      id
      amount
      currentDueDate
      nextDueDate
      originalDueDate
      paidAmount
      refundedAmount
      status
      statusLabel
      totalAmount
      createdAt
      updatedAt
    }
}`

async function getCompetence ( id ) {
  const { $api } = Vue.prototype
  const result = await $api.query({
      query,
      variables: { id },
      fetchPolicy: 'no-cache'
  }).then( res => res )
  .catch( e => e )
  return result;
}

export { getCompetence }
