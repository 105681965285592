import gql from 'graphql-tag'

const query = gql`
  query subscription(
    $id: ID!
  ) {
    subscription(
      id: $id,
  ) {
    id
    frequency
    frequencyLabel
    amount
    paymentDay
    paymentMethod
    paymentMethodLabel
    status
    statusLabel
    createdAt
    updatedAt
    finishedAt
    customer {
      id
      birthDate
      fullName
      firstName
      lastName
      documentType
      identification
    }
    card {
      id
      firstDigits
      lastDigits
      expiration
      cardBrandName
      holderName
    }
    bankAccount {
      id
      accountDigit
      accountNumber
      agencyDigit
      agencyNumber
      bankCode
      bankName
    }
    address {
      id
      street
      number
      neighborhood
      complement
      zipcode
      city
      state
    }
  }
}`

async function getDonationDetail ( id ) {
    const { $api } = Vue.prototype
    const result = await $api.query({
        query,
        variables: { id },
        fetchPolicy: 'no-cache'
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getDonationDetail }
