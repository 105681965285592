import gql from 'graphql-tag'

const query = gql`
  query subscriptions(
    $page: Int = 1,
    $limit: Int = 10,
    $customerId: ID,
    $paymentMethod: String,
    $status: String,
    $customerName: String,
    $amount: Int,
    $amountOperator: String,
    $createdAtBeginDate: ISO8601Date,
    $createdAtEndDate: ISO8601Date,
    $orderBy: String = "createdAt",
    $order: String = "desc"
  ) {
    subscriptions(
      page: $page,
      limit: $limit,
      customerId: $customerId,
      paymentMethod: $paymentMethod,
      status: $status,
      customerName: $customerName,
      amount: $amount,
      amountOperator: $amountOperator,
      createdAtBeginDate: $createdAtBeginDate,
      createdAtEndDate: $createdAtEndDate,
      orderBy: $orderBy,
      order: $order
  ) {
    collection {
      id
      status
      statusLabel
      createdAt
      updatedAt
      finishedAt
      amount
      frequency
      frequencyLabel
      amount
      paymentMethod
      paymentMethodLabel
      customer {
        fullName
      }
    }
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
  }
}`

async function getDonations ( params = {} ) {
    const { $api } = Vue.prototype
    let beginDate = ''
    let endDate = ''

    if (params.createdAtBeginDate && params.createdAtEndDate) {

      let { createdAtBeginDate, createdAtEndDate } = params

      createdAtBeginDate = createdAtBeginDate.split("/")
      createdAtEndDate = createdAtEndDate.split("/")
      beginDate = new Date(createdAtBeginDate[2], createdAtBeginDate[1] - 1, createdAtBeginDate[0]).toISOString().split("T")[0]
      endDate =  new Date(createdAtEndDate[2], createdAtEndDate[1] - 1, createdAtEndDate[0]).toISOString().split("T")[0]

      params.createdAtBeginDate = beginDate
      params.createdAtEndDate = endDate
    }

    const result = await $api.query({
        query,
        variables: params,
        fetchPolicy: 'no-cache'
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getDonations }
