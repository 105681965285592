import gql from 'graphql-tag'

const mutation = gql`
  mutation updateSubscription(
    $id: ID!
    $subscription: UpdateSubscriptionInput!
  ) {
    updateSubscription(
      id: $id
      subscription: $subscription
    ) {
      errors {
        fieldName
        message
      }
    }
}`
/**
 * subscriptionID
 * subscriptionData
 * @param {*} id
 * @param {*} subscription
 */
async function updateDonation ( id, subscription ) {
    const { $api } = Vue.prototype
    const result = await $api.mutate({
      mutation,
      variables: { id, subscription }
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { updateDonation }
