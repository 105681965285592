import gql from 'graphql-tag'

const query = gql`
  query competences(
    $page: Int = 1,
    $limit: Int = 10,
    $subscriptionId: ID,
    $orderBy: String = "originalDueDate",
    $order: String = "desc"
  ) {
    competences(
      page: $page,
      limit: $limit,
      subscriptionId: $subscriptionId,
      orderBy: $orderBy,
      order: $order
  ) {
    collection {
      id
      updatedAt
      status
      statusLabel
      originalDueDate
      subscriptionCycle {
        subscriptionId
      }
    }
    metadata {
      currentPage
      limitValue
      totalCount
      totalPages
    }
  }
}`

async function getCompetences ( params = {} ) {
    const { $api } = Vue.prototype
    const result = await $api.query({
        query,
        fetchPolicy: 'no-cache',
        variables: params
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getCompetences }
