import { mapActions, mapState } from 'vuex'
import { dateTimeFormatDate, textFormatDate, currencyFormat } from '@/helpers'
import { getDonationDetail } from '@/queries/donations/donations.queries'
import { getCompetences } from '@/queries/competences/competences.queries'

export default {
  name: 'TDonationsSinglePage',
  components: {
    TGroupCards: () => import('@/components/group-cards'),
    TGroupHistoryItem: () => import('@/components/group-cards/history-item.vue'),
    TStatusColorBar: () => import('@/components/status/color-bar.vue'),
    TCardDonationDetail: () => import('@/components/donations/card-donation-detail.vue'),
    TFormBillingDonation: () => import('@/components/donations/card-form-billing.vue'),
    TCardPlaceholder: () => import('@/components/card-placeholder')
  },
  data () {
    return {
      headerTitle: 'Doação',
      donationId: null,
      isLoading: false,
      isLoadingCompetences: false,
      competences: []
    }
  },
  computed: {
    ...mapState({
      isLoadingDetail: ({ donations }) => donations.loadingDetail,
      donationDetail: ({ donations }) => donations.donationDetail,
      donorName: ({ donations }) => donations.donationDetail.customer ? donations.donationDetail.customer.fullName : '',
      donorId: ({ donations }) => donations.donationDetail.customer ? donations.donationDetail.customer.id : ''
    }),
    donationAmount: ({ donationDetail }) => `Doação de BRL ${currencyFormat(donationDetail.amount)} ${donationDetail.frequencyLabel}`,
    popup () {
      return `<div class='content'><p>Aqui você pode visualizar os dados da Doação.</p></div>`
    }
  },
  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('donations', [ 'setDonationDetail', 'toggleLoadingDetail' ]),
    bindPlugins () {
      $('.tui.dropdown').dropdown()
      $('.tui.popup').popup({
        transition: 'fade'
      })
    },

    formatDate: (date) => date ? `${dateTimeFormatDate(date)}h` : '',

    textFormatDate: (date) => date ? textFormatDate(date) : '',

    finishedDonation: ( status ) => status === 'finalized',

    getDonationDetail ( id ) {
      this.toggleLoadingDetail(true)
      getDonationDetail( id ).then(res => {
        const { subscription } = res.data
        this.setDonationDetail( subscription )
        this.toggleLoadingDetail()
      }).catch(e => {
        Vue.$log.error('error', e)
        this.toggleLoadingDetail()
      })
    },

    getCompetences ( donationId ) {
      this.isLoadingCompetences = true
      getCompetences({ subscriptionId: donationId }).then(res => {
        const { collection } = res.data.competences
        this.competences = collection
        this.isLoadingCompetences = false
      }).catch(e => {
        Vue.$log.error('error', e)
        this.isLoadingCompetences = false
      })
    }
  },
  mounted () {
    const { id } = this.$route.params
    this.headerTitle = `${this.headerTitle} ${ id ? `<small class="donation-id">#${id}</small>` : ''} `
    this.donationId = id
    this.getDonationDetail( this.donationId )
    this.getCompetences( this.donationId )
    this.bindPlugins()
    this.changeBreadcrumbItems([
      { to: { name: 'donations' }, title: 'Doações', active: false },
      { to: { name: 'donations.single', params: { id } }, title: 'Doação', active: true }
    ])
  }
}
