var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "pageDonations" } },
    [
      _c("t-page-header", {
        attrs: { title: _vm.$tc("Donation", 2) },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    class: [
                      "tui tuim ui basic button",
                      { disabled: _vm.isLoadingTotals || _vm.isLoading }
                    ],
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.callCreateReport()
                      }
                    }
                  },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: "file" }
                    }),
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("ExportTo", { to: ".xlsx" })) +
                        "\n      "
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "notifications",
            fn: function() {
              return [_c("t-notification")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            [
              _c("t-filter", {
                ref: "filterDonations",
                attrs: {
                  applyFilter: _vm.applyFilter,
                  form: _vm.filterForm,
                  filterItems: _vm.filterItems,
                  filterUpdated: _vm.filtersUpdated
                },
                scopedSlots: _vm._u([
                  {
                    key: "fields",
                    fn: function() {
                      return [_c("t-donations-filters")]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "t-page-content",
            [
              _vm.isLoadingTotals && _vm.isLoading
                ? _c("t-placeholder", {
                    staticClass: "donations-totals",
                    attrs: { lines: 2, paragraph: true }
                  })
                : _c("t-status-bar", {
                    staticClass: "donations",
                    scopedSlots: _vm._u([
                      {
                        key: "leftContent",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "tui tuim item status-item totals"
                              },
                              [
                                _c("span", {
                                  staticClass: "label",
                                  domProps: {
                                    textContent: _vm._s(_vm.totals.label)
                                  }
                                }),
                                _c("span", {
                                  staticClass: "value",
                                  domProps: {
                                    textContent: _vm._s(_vm.totals.value)
                                  }
                                }),
                                _c("span", {
                                  staticClass: "symbol",
                                  domProps: { innerHTML: _vm._s("&nbsp;") }
                                })
                              ]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "rightContent",
                        fn: function() {
                          return _vm._l(_vm.statusItems, function(item, k) {
                            return _c("t-status-bar-item", {
                              key: k,
                              attrs: { item: item }
                            })
                          })
                        },
                        proxy: true
                      }
                    ])
                  }),
              _vm.donationsData.length === 0 && !_vm.isLoading
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhuma doação",
                      subheader: "Não há doações"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("t-donations-table", { on: { orderBy: _vm.orderBy } }),
                      _vm.totalPages > 1 && !_vm.isLoading
                        ? _c("t-pagination", {
                            attrs: {
                              btnClickPrev: this.prevPage,
                              btnClickNext: this.nextPage,
                              totalPages: _vm.totalPages,
                              currentPage: _vm.currentPage,
                              pagesSelected: _vm.pagesSelected,
                              pages: [5, 10, 50, 100],
                              goToPage: _vm.goToPage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          ref: "modalConfirmReport",
          staticClass: "ui tiny modal confirm-report"
        },
        [
          _c("div", { staticClass: "header" }, [
            _vm._v("Confirmar exportação")
          ]),
          _c("div", { staticClass: "content" }, [
            _vm._m(0),
            _vm._m(1),
            _c("p", [
              _c("span", {
                staticClass: "user-email",
                domProps: { textContent: _vm._s(_vm.user.email) }
              })
            ])
          ]),
          _c("div", { staticClass: "actions" }, [
            _c("div", { staticClass: "ui cancel button" }, [
              _vm._v("\n        Cancelar\n      ")
            ]),
            _c(
              "div",
              {
                staticClass: "ui approve primary button",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.generateReport()
                  }
                }
              },
              [_vm._v("\n        Exportar\n      ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "message" }, [
      _c("p", [_vm._v("Tem certeza que deseja exportar esse relatório?")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _vm._v(
          "O processo de exportação pode demorar alguns minutos.  O arquivo será enviado\n          para o e-mail abaixo assim que concluído."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }